import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';

@Component({
  selector: 'app-serviceseeker',
  templateUrl: './serviceseeker.component.html',
  styleUrls: ['./serviceseeker.component.scss']
})
export class ServiceseekerComponent implements OnInit {

  url = environment.baseUrl;
  term: any;
  perPage = 10
  totalItems = 0
  constructor(
    public r: Router,
    private modalService: NgbModal,
    public d: DataService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Service Seekers';
  }
  getImage(logo) {
    return logo ? environment.baseImageUrl + logo : 'assets/avatar.png';
    if (logo) {
      return this.url + '/uploads/' + logo;
    } else {
      return 'assets/avatar.png'
    }
  }
  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  perPageChange(e) {
    let val = e.target.value
    if (val === 'All') val = this.mfData.length
    this.perPage = val
  }
  mfData = [];
  page: number = 1;
  ngOnInit() {
    this.mfData = [];
    this.d.getCustomer(1).subscribe((respo: any) => {
      this.mfData = respo.data;
      this.totalItems = this.mfData.length
    });
  }
  key: string = 'name';
  reverse: boolean = false;

  sortData(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  // ========== delete ===========
  deleid: any;
  getdelId(id) {
    this.deleid = id;
  }
  del() {
    console.log(this.deleid)
    this.d.removeCustomer(this.deleid).subscribe((res: any) => {
      this.ngOnInit()
    })
  }
  // ----------- edit -------
  getedit(item) {
    localStorage.setItem('editserviceseeker', JSON.stringify(item));
    this.r.navigate(['/editcustomer'])
  }
}
