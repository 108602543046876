import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public r: Router, public d: DataService) { }

  ngOnInit() {
  }

  logindata = {
    email: '',
    password: ''
  };

  err = '';
  cond = true;

  login() {
    this.d.login(this.logindata).subscribe((resp: any) => {
      if (resp.success && resp.data) {
        this.cond = true;
        this.r.navigate(['/services']);
        localStorage.setItem('verifiedflash268', resp.data.token);
      }
    }, (err) => {
      this.err = 'Invalid email or password';
      this.cond = false;
    });
  }
}
