import { Component, OnInit } from '@angular/core';
import { AppGlobalsService } from '../app-globals.service';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {

  term = ''
  page = 1
  perPage = 10
  totalItems = 0
  today: Date = new Date()
  constructor(
    private d: DataService,
    public g: AppGlobalsService,
    private t: ToastService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Coupons';
    this.getCoupons()
  }

  perPageChange(e) {
    let val = e.target.value
    if (val === 'All') val = this.coupons.length
    this.perPage = val
  }

  ngOnInit() {
  }

  data: any = {
    code: '',
    type: '',
    discount: 0,
    allowedRedeems: 10,
    totalRedeems: 0,
    expiryDate: ''
  }
  coupons = []
  couponTypes = [
    {
      name: 'Percentage',
      value: 'PERC'
    },
    {
      name: 'Flat',
      value: 'FLAT'
    }
  ]
  getCoupons() {
    this.d.getAllCoupon().subscribe((resp: any) => {
      this.coupons = resp.data;
      this.totalItems = this.coupons.length
    })
  }

  cleardata() {
    this.data = {
      code: '',
      type: '',
      discount: 0,
      allowedRedeems: 10,
      totalRedeems: 0,
      expiryDate: null,
    }
  }

  key: string = 'name';
  reverse: boolean = false;

  sortData(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  addCoupon() {
    this.d.addCoupon(this.data).subscribe((resp: any) => {
      if (resp.status === 'error') {
        this.t.show('error', 'Error!', resp.message);
      } else {
        this.t.show('success', 'Notification', 'Added Successfully');
        this.getCoupons()
        this.cleardata()
      }
    })
  }

  getedit(item) {
    this.data = item
    this.data['expiryDate'] = this.data.expiryDate.split("T")[0]
  }

  del() {
    this.d.deleteCoupon(this.data.id).subscribe((resp: any) => {
      if (resp.status === 'error') {
        this.t.show('error', 'Error!', resp.message);
      } else {
        this.t.show('success', 'Notification', 'Deleted Successfully');
        this.getCoupons()
        this.cleardata()
      }
    })
  }

  updateCouponInfo() {
    this.d.updateCoupon(this.data).subscribe((resp: any) => {
      if (resp.status === 'error') {
        this.t.show('error', 'Error!', resp.message);
      } else {
        this.t.show('success', 'Notification', 'Updated Successfully');
        this.getCoupons()
        this.cleardata()
      }
    })
  }
}
