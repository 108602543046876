import { Component, OnInit } from '@angular/core';
import { AppGlobalsService } from '../app-globals.service';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss',
'../../../node_modules/ngx-toastr/toastr.css']
})
export class TranslationComponent implements OnInit {

  constructor(
    private d: DataService,
    public g: AppGlobalsService,
    private t: ToastService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Translations';
   }

  term = ''
  languages = []
  page: number = 1
  ngOnInit() {
    this.getLanguages()
    this.getMobilePages()
  }
  perPage = 10
  totalItems = 0
  perPageChange(e) {
    let val = e.target.value
    if(val === 'All') val = this.translations.length
    this.perPage = val
  }
  init_page = {
    id: 0,
    name: 'Select Page'
  }
  selectedpage = this.init_page
  mobile_pages = [this.init_page]
  getMobilePages() {
    this.d.all_mobile_pages().subscribe((res: []) => {
      this.mobile_pages = [this.init_page]
      res.forEach(elt => {
        this.mobile_pages.push(elt)
      });
    })
  }

  getLanguages() {
    this.d.getAllLanguage().subscribe((res: any) => {
      this.languages = res.respo
      this.getTranslations()
    })
  }

  translations
  getTranslations() {
    this.d.all_translations().subscribe((res: []) => {
      this.translations = res
      this.totalItems = this.translations.length
    })
  }

  default
  language
  phraseId
  languageId
  translaions
  getedit(translation, language) {
    this.phraseId = translation.id
    this.languageId = language.id
    this.default = translation.default
    this.language = language.name
    this.updateData = translation.translations[language.id]
    this.translations = translation.translations
  }

  updateData
  updateTranslation() {    
    const isUpdate = this.translations.hasOwnProperty(this.languageId)
    let data = {
      phraseId: this.phraseId,
      languageId: this.languageId,
      translation: this.updateData
    }
    if(isUpdate) {
      this.d.update_translation(data).subscribe(res => {
        this.getTranslations()
      })
    } else {
      this.d.add_translation(data).subscribe(res => {
        this.getTranslations()
      })
    }
  }

  newTranslation = {
    phrase: '',
    default_trans: ''
  }
  addPhrase() {
    if(this.selectedpage.id == 0) {
      this.t.show('success', 'Notification', 'Deleted Successfully');
      return
    }
    this.newTranslation['pageId'] = this.selectedpage.id
    this.d.add_phrase(this.newTranslation).subscribe(res => {
      this.newTranslation.phrase = ''
      this.newTranslation.default_trans = ''
      this.getTranslations()
    })
  }

  phrase
  geteditpage(item) {
    this.phrase = item
    this.selectedpage = item.page
  }

  updatePage() {
    this.d.update_phrase(this.phrase.id, this.selectedpage.id).subscribe(res => {
      this.selectedpage.id = 0
      this.selectedpage.name = 'Select Page'
      this.phrase = {}
      this.getTranslations()
    })
  }

}
