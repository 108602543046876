import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: [
    '../../vendor/styles/pages/products.scss',
    '../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    '../../vendor/libs/ng-select/ng-select.scss'
  ]
})
export class LanguageComponent implements OnInit {

  language = ''
  abbri = ''
  constructor(
    private modalService: NgbModal,
    public d: DataService,
    private t: ToastService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Languages';
  }

  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  term = ''

  mfData = []
  page: number = 1
  ngOnInit() {
    this.getLanguages()
  }

  perPage = 10
  totalItems = 0
  perPageChange(e) {
    let val = e.target.value
    if (val === 'All') val = this.mfData.length
    this.perPage = val
  }

  getLanguages() {
    this.d.getAllLanguage().subscribe((res: any) => {
      this.mfData = res.data
      this.totalItems = this.mfData.length
    })
  }

  key: string = 'name';
  reverse: boolean = false;

  sortData(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  save() {
    this.d.addLanguage({
      name: this.language,
      abbr: this.abbri
    }).subscribe((res: any) => {
      this.language = ''
      this.getLanguages()
      this.t.show('success', 'Notification', 'Added Successfully');
    })
  }

  // ===== delete ==== //

  delId
  getdelId(item) {
    this.delId = item.id
    this.language = item.name
  }

  del() {
    this.d.removeLanguage(this.delId).subscribe((res: any) => {
      this.language = ''
      this.getLanguages()
      this.t.show('success', 'Notification', 'Deleted Successfully');
    })
  }

  // ===== edit ==== //

  // ===========edit===========
  updateData = {
    language: '',
    abbr: ''
  }
  editId: any;
  getedit(edit) {
    console.log(edit);
    this.editId = edit.id;
    this.updateData.language = edit.name;
    this.updateData.abbr = edit.abbr;
  }
  updateLanguage() {
    this.d.updateLanguage(this.updateData).subscribe((resp: any) => {
      this.getLanguages()
      this.t.show('success', 'Notification', 'Updated Successfully');
    })
  }

}
