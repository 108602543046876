import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  type = "success";
  tapToDismiss = true;
  closeButton = true;
  progressBar = false;
  preventDuplicates = false;
  newestOnTop = false;
  progressAnimation = "increasing";
  positionClass = "toast-top-right";

  constructor(
    private appService: AppService,
    public toastrService: ToastrService
  ) { }

  show(type, title, message) {
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[type](message, title, options);
  }

  clear() {
    this.toastrService.clear();
  }
}
