import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { ServiceproviderComponent } from './serviceprovider/serviceprovider.component';
import { ServiceseekerComponent } from './serviceseeker/serviceseeker.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgxPaginationModule } from 'ngx-pagination';
// *******************************************************************************
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SubcatComponent } from './subcat/subcat.component';
import { ServicesComponent } from './services/services.component';
import { LoginComponent } from './login/login.component';
import { PlanComponent } from './plan/plan.component';
import { SubplanComponent } from './subplan/subplan.component';
import { AdditionalsComponent } from './additionals/additionals.component';
import { ToastrModule } from 'ngx-toastr';
import { EditserviceproviderComponent } from './editserviceprovider/editserviceprovider.component';
import { EditserviceseekerComponent } from './editserviceseeker/editserviceseeker.component';
import { PlanslookingforComponent } from './planslookingfor/planslookingfor.component';
import { ProjectslookingforComponent } from './projectslookingfor/projectslookingfor.component';
import { EditlookingforComponent } from './editlookingfor/editlookingfor.component';
import { NgxMultiSelectModule } from '@ngx-tiny/multi-select';
import { LanguageComponent } from './language/language.component';
import { TranslationComponent } from './translation/translation.component';
import { MobilepagesComponent } from './mobilepages/mobilepages.component';
import { CouponsComponent } from './coupons/coupons.component';
import {Ng2OrderModule} from 'ng2-order-pipe';

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    HomeComponent,
    Page2Component,
    ServiceproviderComponent,
    ServiceseekerComponent,
    SubcatComponent,
    ServicesComponent,
    LoginComponent,
    PlanComponent,
    SubplanComponent,
    AdditionalsComponent,
    EditserviceproviderComponent,
    EditserviceseekerComponent,
    PlanslookingforComponent,
    ProjectslookingforComponent,
    EditlookingforComponent,
    LanguageComponent,
    TranslationComponent,
    MobilepagesComponent,
    CouponsComponent
  ],

    imports: [

        BrowserModule,
        BrowserAnimationsModule,
        NgxMultiSelectModule,
        NgbModule.forRoot(),
        HttpClientModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        NgSelectModule,
        ToastrModule.forRoot({
            timeOut: 1500,
            // positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        FormsModule,
        // App
        AppRoutingModule,
        LayoutModule,
        Ng2OrderModule
    ],

  providers: [
    Title,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppService
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
