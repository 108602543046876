import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import * as numeral from 'numeral';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment.prod';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subcat',
  templateUrl: './subcat.component.html',
  styleUrls: [
    '../../vendor/styles/pages/products.scss',
    '../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    '../../vendor/libs/ng-select/ng-select.scss'
  ]
})
export class SubcatComponent {

  isRTL: boolean;
  allCats = [];
  url: any;
  constructor(private http: HttpClient,
    public d: DataService,
    public active: ActivatedRoute,
    private modalService: NgbModal, private appService: AppService) {
    this.appService.pageTitle = 'Sub Categories';
    this.isRTL = appService.isRTL;

    if (appService.isRTL) {
      this.filterSalesOptions['direction'] = 'rtl';
      this.filterPriceOptions['direction'] = 'rtl';
    }
    this.url = environment.baseUrl;



  }


  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  // Filters

  filterSales = [10, 834];
  filterPrice = [10, 2000];
  filterStatus = 'Any';

  // Filter options
  filterSalesOptions: Object = {
    step: 10,
    tooltips: true,
    range: {
      'min': [10],
      'max': [834]
    },
    direction: 'ltr',
    format: {
      to: function (value: any) {
        return Math.round(value);
      },
      from: function (value) {
        return value;
      }
    }
  };
  filterPriceOptions: Object = {
    step: 50,
    tooltips: true,
    range: {
      'min': [10],
      'max': [2000]
    },
    direction: 'ltr',
    format: {
      to: function (value) {
        return numeral(value).format('$0,0');
      },
      from: function (value) {
        return value.replace(/[$,]/g, '');
      }
    }
  };

  // Table

  // Options
  // dataUrl = 'assets/json/pages_e-commerce_product-list.json';
  searchKeys = ['name'];
  sortBy = 'name';
  sortDesc = true;
  perPage = 5;

  filterVal = '';
  currentPage = 1;
  totalItems = 0;
  catagory: any;
  productsData: Object[] = [];
  originalProductsData: Object[] = [];

  formatPrice(price, dropDecimals = false) {
    return numeral(price).format(dropDecimals ? '$0,0' : '$0,0.00');
  }

  formatNumber(value) {
    return numeral(value).format('0,0');
  }

  loadData(id) {
    // this.http.get(this.dataUrl)
    //   .subscribe((data: any) => {
    //     this.originalProductsData = data.slice(0);
    //     this.update();
    //   });
    // this.d.show_catsubcat().subscribe((res: any) => {
    //   console.log(res)
    //   res.forEach(element => {
    //     this.selectOptions.push({ value: element.id, label: element.name })
    //   });
    // })

    this.active.params.subscribe(resp => {
      this.catagory = resp.cat
    })
    this.d.getAllSubCat(id).subscribe((resp: any) => {
      this.allCats = resp.data.sort((a, b) => a.name.localeCompare(b.name))
      console.log(resp.data)
    });
  }

  get totalPages() {
    return Math.ceil(this.totalItems / this.perPage);
  }
  allModalServices = []
  showServices(data) {
    this.allModalServices = data;
    console.log(data)
  }
  update() {
    const data = this.filter(this.originalProductsData);

    this.totalItems = data.length;

    // this.sort(data);
    this.allCats = this.paginate(data);
  }

  filter(data) {
    const filter = this.filterVal.toLowerCase();
    return !filter ?
      data.slice(0) :
      data.filter(d => {
        return Object.keys(d)
          .filter(k => this.searchKeys.includes(k))
          .map(k => String(d[k]))
          .join('|')
          .toLowerCase()
          .indexOf(filter) !== -1 || !filter;
      });
  }

  sort() {
    this.allCats.sort((a: any, b: any) => {
      a = typeof (a[this.sortBy]) === 'string' ? a[this.sortBy].toUpperCase() : a[this.sortBy];
      b = typeof (b[this.sortBy]) === 'string' ? b[this.sortBy].toUpperCase() : b[this.sortBy];

      if (a > b) { return this.sortDesc ? 1 : -1; }
      if (a < b) { return this.sortDesc ? -1 : 1; }
      return 0;
    });
  }

  key: string = 'name';
  reverse: boolean = false;

  sortData(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  paginate(data) {
    const perPage = parseInt(String(this.perPage), 10);
    const offset = (this.currentPage - 1) * perPage;

    return data.slice(offset, offset + perPage);
  }

  setSort(key) {
    if (this.sortBy !== key) {
      this.sortBy = key;
      this.sortDesc = false;
    } else {
      this.sortDesc = !this.sortDesc;
    }

    this.currentPage = 1;
    this.update();
  }
  // ===============
  disabled = false;
  selectOptions = [ // tslint:disable
  ]; // tslint:enable

  filename = 'Choose file';
  imag: any;
  addData = {
    name: '',
    catsubcatId: ''
  }
  catId: any;

  selectFile(event) {
    this.imag = event.target.files[0];
    this.filename = this.imag.name;
    // this.d.uploadFiles(event.target.files[0]).subscribe((resp: any) => {
    //   console.log(resp);
    //   this.addData._imageUrl = resp;
    //   this.updateData._imageUrl = resp;
    // })
  }
  ngOnInit() {
    this.active.params.subscribe((resp: any) => {
      this.loadData(resp.id);
      this.addData.catsubcatId = resp.id;
      this.updateData.catsubcatId = resp.id;
    })
  }
  save() {
    // this.selectOptions.forEach(element => {
    //   if (element.value == this.catId) {
    //     console.log(element.label)
    //     this.addData._categoryName = element.label;
    //   }
    // });
    console.log(this.addData);
    this.d.addSubCat(this.addData).subscribe((resp: any) => {
      console.log(resp);
      // this.loadData()
      this.ngOnInit();
      this.addData.name = '';
      // this.addData._categoryName = '';
      // this.addData._imageUrl = '';
      // this.filename = 'Choose Image'
      // this.allCats = [];
      // this.d.show_catsubcat().subscribe((resp: any) => {
      //   this.allCats = resp
      // });
    })
  }
  // ========== delete ===========
  deleid: any;
  getdelId(id) {
    this.deleid = id;
  }
  del() {
    console.log(this.deleid)
    this.d.removeCatsubcat(this.deleid).subscribe((res: any) => {
      this.ngOnInit();
    })
  }
  // ===========edit===========
  updateData = {
    id: '',
    name: '',
    catsubcatId: '',
  }
  getedit(edit) {
    console.log(edit);
    this.updateData.name = edit.name;
    this.updateData.id = edit.id;
  }
  updateCAT() {
    this.d.editSubcat(this.updateData).subscribe((resp: any) => {
      this.allCats = [];
      console.log(resp);
      this.ngOnInit()
    })
  }


}
