import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DataService } from "../data.service";
import { ToastrService } from "ngx-toastr";
import { AppService } from "../app.service";
@Component({
  selector: 'app-additionals',
  templateUrl: './additionals.component.html',
  styleUrls: ["../../vendor/libs/ngx-toastr/ngx-toastr.scss"],
  encapsulation: ViewEncapsulation.None
  // styleUrls: ['./additionals.component.scss']
})
export class AdditionalsComponent implements OnInit {
  curMsgIndex = -1;
  title = "Notification";
  message = "Successfully updated";
  type = "success";
  tapToDismiss = true;
  closeButton = true;
  progressBar = false;
  preventDuplicates = false;
  newestOnTop = false;
  progressAnimation = "increasing";
  positionClass = "toast-top-right";
  data = {
    invite: "",
    forgot: "",
    apikey:"",
    onesignalappid: "",
    onesignalrestkey: "",
    googleprojectid: "",
    paypalclientid: "",
    paypalsecret: "",
    appversionnumber: ""
  };
  constructor(
    public d: DataService,
    private appService: AppService,
    public toastrService: ToastrService
  ) {
    this.appService.pageTitle = 'Additionals';
  }
  showToast(type, title, message) {
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[type](this.message, this.title, options);
  }

  clearToasts() {
    this.toastrService.clear();
  }

  ngOnInit() {
    this.d.allAdditional().subscribe((responce: any) => {
      console.log(responce);
      this.data = responce;
    });
  }
  update() {
    this.d.updateAdditional(this.data).subscribe((resp: any) => {
      console.log(resp);
      this.showToast('success', 'Notification', 'Updated Successfully');
    })
  }
}
