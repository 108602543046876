import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { FileUploadService } from '../file-upload.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-editserviceseeker',
  templateUrl: './editserviceseeker.component.html',
  styleUrls: ["../../vendor/libs/ngx-toastr/ngx-toastr.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EditserviceseekerComponent implements OnInit {
  url = environment.baseUrl;

  curMsgIndex = -1;
  title = "Notification";
  message = "Successfully updated";
  type = "success";
  tapToDismiss = true;
  closeButton = true;
  progressBar = false;
  preventDuplicates = false;
  newestOnTop = false;
  progressAnimation = "increasing";
  positionClass = "toast-top-right";
  constructor(
    private appService: AppService,
    private modalService: NgbModal,
    public toastrService: ToastrService,
    public d: DataService,
    private f: FileUploadService) { }

  data = {
    id: '',
    contactName: '',
    mobileNumber: '',
    email: '',
    country: '',
    city: '',
    province: '',
    postalCode: '',
    streetAddress: '',
    suiteNumber: '',
    logo: ''
  }
  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('editserviceseeker'));
    console.log(this.data)
  }
  getImage(logo) {
    if (logo !== '') {
      return this.url + logo;
    } else {
      return 'assets/avatar.png'
    }
  }
  fileSelected(event) {
    const file: File | null = event.target.files.item(0)
    if(file) {
      this.f.upload(file).subscribe((resp: any) => {
        let fname = resp.body
        this.d.updateProfileCustomer({logo: fname}, this.data.id).subscribe(res => {
          this.data['logo'] = fname
        })
      })
    }
  }
  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  mfData = [];
  page: number = 1;
  save() {
    console.log(this.data);
    this.d.editcompanyfromadminpannel(this.data).subscribe((resp: any) => {
      console.log(resp.data);
    })
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }
  showToast() {
    console.log('toast called')
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }

  clearToasts() {
    this.toastrService.clear();
  }
  upd = {
    password: ''
  }
  updatePass() {
    console.log(this.upd);
    this.d.resetPaswordCustomer(this.data.id, this.upd).subscribe((rep) => {
      console.log(rep);
      const options = {
        tapToDismiss: this.tapToDismiss,
        closeButton: this.closeButton,
        progressBar: this.progressBar,
        progressAnimation: this.progressAnimation,
        positionClass: this.positionClass,
        rtl: this.appService.isRTL
      };

      this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
      this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

      this.toastrService[this.type](this.message, this.title, options);
    })
  }
}
