import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
@Component({
  selector: 'app-editlookingfor',
  templateUrl: './editlookingfor.component.html',
  styleUrls: ["../../vendor/libs/ngx-toastr/ngx-toastr.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EditlookingforComponent implements OnInit {
  curMsgIndex = -1;
  title = "Notification";
  message = "Successfully updated";
  type = "success";
  tapToDismiss = true;
  closeButton = true;
  progressBar = false;
  preventDuplicates = false;
  newestOnTop = false;
  progressAnimation = "increasing";
  positionClass = "toast-top-right";
  url = environment.baseUrl;
  imageUrl = environment.baseImageUrl;
  constructor(
    private appService: AppService,
    public toastrService: ToastrService,
    private modalService: NgbModal,
    public d: DataService) { }

  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  data = {
    id: 0,
    planTitle: '',
    description: '',
    fromDate: '',
    toDate: '',
    startDate: '',
    customer: {},
    budget: '',
    rangeFrom: '',
    rangeTo: '',
    serviceId: '',
    categoryId: '',
    subCategoryId: ''
  }
  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('editlookingfor'));
    console.log(this.data)
    if (this.data.fromDate) {
      this.data.fromDate = this.data.fromDate.substr(0, 10)
    }
    if (this.data.toDate) {
      this.data.toDate = this.data.toDate.substr(0, 10)
    }
    if (this.data.startDate) {
      this.data.startDate = this.data.startDate.substr(0, 10)
    }
    this.d.getLookingforImage(this.data.id).subscribe((resp: any) => {
      console.log(resp);
      this.images = resp.data;
    });
    this.d.getAllCategory().subscribe((resp: any) => {
      this.catagories = resp.data;
    })
    this.d.getAllSubCat(null).subscribe((resp: any) => {
      this.subcatagories = resp.data;
    })
    this.d.getAllService().subscribe((resp: any) => {
      this.services = resp.data;
    })
  }
  images = [];
  catagories = [];
  subcatagories = [];
  services = []
  remove(item) {
    console.log(item);
    this.d.removeLookingforImage(item.id).subscribe((resp: any) => {
      console.log(resp);
      this.d.getLookingforImage(this.data.id).subscribe((resp: any) => {
        console.log(resp);
        this.images = resp;
      })
    })
  }
  save() {
    console.log(this.data);
    delete this.data.customer;
    this.d.updatePlanLookingFor(this.data).subscribe((resp: any) => {
      console.log(resp);
    })
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }
  showToast() {
    console.log('toast called')
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }

  clearToasts() {
    this.toastrService.clear();
  }

}
