import { Component, OnInit } from '@angular/core';
import { AppGlobalsService } from '../app-globals.service';
import { AppService } from '../app.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-mobilepages',
  templateUrl: './mobilepages.component.html',
  styleUrls: ['./mobilepages.component.scss']
})
export class MobilepagesComponent implements OnInit {

  term = ''
  page = 1
  perPage = 10
  totalItems = 0
  constructor(
    private d: DataService,
    public g: AppGlobalsService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Mobile Pages';
    this.getMobilePages()
  }

  perPageChange(e) {
    let val = e.target.value
    if(val === 'All') val = this.pages.length
    this.perPage = val
  }

  ngOnInit() {
  }

  pages = []
  getMobilePages() {
    this.d.all_mobile_pages().subscribe((res: []) => {
      this.pages = res
      this.totalItems = this.pages.length
    })
  }

  page_info = {
    name: '',
    tag: ''
  }
  add_page() {
    this.d.add_mobile_page(this.page_info).subscribe(res => {
      this.getMobilePages()
      this.page_info = {
        name: '',
        tag: ''
      }
    })
  }

  page_id
  getedit(page) {
    this.page_id = page.id
    this.page_info.name = page.name
    this.page_info.tag = page.tag
  }

  updatePageInfo() {
    this.d.update_mobile_page(this.page_id, this.page_info).subscribe(res => {
      this.getMobilePages()
      this.page_id = ''
      this.page_info = {
        name: '',
        tag: ''
      }
    })
  }

  del() {
    this.d.delete_mobile_page(this.page_id).subscribe(res => {
      this.getMobilePages()
      this.page_id = ''
      this.page_info = {
        name: '',
        tag: ''
      }
    })
  }

}
