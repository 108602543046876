import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { Layout2Component } from './layout/layout-2/layout-2.component';
import { ServiceproviderComponent } from './serviceprovider/serviceprovider.component';
import { ServiceseekerComponent } from './serviceseeker/serviceseeker.component';
import { SubcatComponent } from './subcat/subcat.component';
import { ServicesComponent } from './services/services.component';
import { LoginComponent } from './login/login.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { AuthGuard } from './auth.guard';
import { ForwardGuard } from './forward.guard';
import { PlanComponent } from './plan/plan.component';
import { SubplanComponent } from './subplan/subplan.component';
import { AdditionalsComponent } from './additionals/additionals.component';
import { EditserviceproviderComponent } from './editserviceprovider/editserviceprovider.component';
import { EditserviceseekerComponent } from './editserviceseeker/editserviceseeker.component';
import { PlanslookingforComponent } from './planslookingfor/planslookingfor.component';
import { ProjectslookingforComponent } from './projectslookingfor/projectslookingfor.component';
import { EditlookingforComponent } from './editlookingfor/editlookingfor.component';
import { LanguageComponent } from './language/language.component';
import { TranslationComponent } from './translation/translation.component';
import { MobilepagesComponent } from './mobilepages/mobilepages.component';
import { CouponsComponent } from './coupons/coupons.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  {
    path: '', component: LayoutBlankComponent, canActivate: [AuthGuard], pathMatch: 'full', children: [
      { path: '', component: LoginComponent },
    ]
  },

  {
    path: 'page-2', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: Page2Component },
    ]
  },
  {
    path: 'subcat/:id/:cat', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: SubcatComponent },
    ]
  },
  {
    path: 'home', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: HomeComponent },
    ]
  },
  {
    path: 'services', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: ServicesComponent },
    ]
  },
  {
    path: 'languages', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: LanguageComponent },
    ]
  },
  {
    path: 'translations', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: TranslationComponent },
    ]
  },
  {
    path: 'mobilepages', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: MobilepagesComponent },
    ]
  },
  {
    path: 'coupons', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: CouponsComponent },
    ]
  },
  {
    path: 'serviceprovider', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: ServiceproviderComponent },
    ]
  },
  {
    path: 'serviceseeker', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: ServiceseekerComponent },
    ]
  },
  {
    path: 'plan', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: PlanComponent },
    ]
  },
  {
    path: 'subplan/:id/:cat', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: SubplanComponent },
    ]
  },
  {
    path: 'additionals', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: AdditionalsComponent },
    ]
  }
  ,
  {
    path: 'editcompany', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: EditserviceproviderComponent },
    ]
  }
  ,
  {
    path: 'editcustomer', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: EditserviceseekerComponent },
    ]
  },
  {
    path: 'planslookingfor', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: PlanslookingforComponent },
    ]
  },
  {
    path: 'projectslookingfor', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: ProjectslookingforComponent },
    ]
  },
  {
    path: 'editlookingfor', component: Layout2Component, canActivate: [ForwardGuard], children: [
      { path: '', component: EditlookingforComponent },
    ]
  }
];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
