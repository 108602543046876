import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(public http: HttpClient) { }
  ApiUrl = environment.baseUrl;

  login(data: {}) {
    return this.http.post(this.ApiUrl + "customer/login", data);
  }

  logout() {
    return this.http.post(this.ApiUrl + "customer/logout", null, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllService() {
    return this.http.get(this.ApiUrl + "service/get-all", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  addService(data: {}) {
    return this.http.post(this.ApiUrl + "service/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  editService(data: {}) {
    return this.http.post(this.ApiUrl + "service/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeService(id: number) {
    return this.http.post(this.ApiUrl + 'service/remove', { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  addCategory(data: {}) {
    return this.http.post(this.ApiUrl + "catsubcat/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  editCategory(data: {}) {
    return this.http.post(this.ApiUrl + "catsubcat/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  addSubCat(data: {}) {
    return this.http.post(this.ApiUrl + "catsubcat/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  editSubcat(data: {}) {
    return this.http.post(this.ApiUrl + "catsubcat/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeCatsubcat(id: number) {
    return this.http.post(this.ApiUrl + "catsubcat/remove", { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllLanguage() {
    return this.http.get(this.ApiUrl + "language/get-all", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  addLanguage(data: {}) {
    return this.http.post(this.ApiUrl + "language/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  updateLanguage(data: {}) {
    return this.http.post(this.ApiUrl + "language/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeLanguage(id: number) {
    return this.http.post(this.ApiUrl + "language/remove", { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  getAllCategory() {
    return this.http.get(this.ApiUrl + "catsubcat/get-all-category", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllSubCat(id) {
    return this.http.get(this.ApiUrl + "catsubcat/get-all-subcategory", {
      params: id != null ? { 'categoryId': id } : null,
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }


  // todo: remove unessecery
  getAllPlan() {
    return this.http.get(this.ApiUrl + "lookingfor/get-all-lookingfor?type=1", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllProject() {
    return this.http.get(this.ApiUrl + "lookingfor/get-all-lookingfor?type=2", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeLookingfor(id: number) {
    return this.http.post(this.ApiUrl + "lookingfor/remove", { id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getLookingforImage(lookingforId: number) {
    return this.http.get(this.ApiUrl + "lookingfor/get-lookingfor-image", {
      params: { "lookingforId": lookingforId.toString() },
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeLookingforImage(id: number) {
    return this.http.post(this.ApiUrl + 'remove-lookingfor-image', { id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllPlanAndFeature() {
    return this.http.get(this.ApiUrl + "plan/get-all", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  addPlan(data: {}) {
    return this.http.post(this.ApiUrl + "plan/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  updatePlan(data: {}) {
    return this.http.post(this.ApiUrl + "plan/update-plan", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removePlan(id: number) {
    return this.http.post(this.ApiUrl + "plan/remove-plan", { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getFeature(id: number) {
    return this.http.get(this.ApiUrl + "plan/get-feature?planId=" + id, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  addFeature(data: {}) {
    return this.http.post(this.ApiUrl + "plan/create-feature", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  updateFeature(data: {}) {
    return this.http.post(this.ApiUrl + "plan/update-feature", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeFeature(id: number) {
    return this.http.post(this.ApiUrl + "plan/remove-feature", { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getAllCoupon() {
    return this.http.get(this.ApiUrl + "coupon/get-all", {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  addCoupon(data) {
    return this.http.post(this.ApiUrl + "coupon/create", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  deleteCoupon(id: number) {
    return this.http.post(this.ApiUrl + "coupon/remove", { id: id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  updateCoupon(data) {
    return this.http.post(this.ApiUrl + "coupon/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    })
  }

  updatePlanLookingFor(data) {
    return this.http.post(this.ApiUrl + "lookingfor/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  // type: 1:customer 2:company
  getCustomer(type: number) {
    return this.http.get(this.ApiUrl + "customer/get-all?type=" + type, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  removeCustomer(id: number) {
    return this.http.post(this.ApiUrl + "customer/remove", { id }, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  getComserv(customerId: number) {
    return this.http.get(this.ApiUrl + "customer/get-comserv", {
      params: { "customerId": customerId.toString() },
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }

  updateProfileImageCompany(data, companyId) {
    return this.http.post(this.ApiUrl + "company/updateprofilepic/" + companyId, data,)
  }
  updateProfileCustomer(data, customerId) {
    return this.http.post(this.ApiUrl + "customer/updateprofilepic/" + customerId, data);
  }
  all_translations() {
    return this.http.get(this.ApiUrl + "translation/all")
  }
  add_translation(data) {
    return this.http.post(this.ApiUrl + "translation/create", data)
  }
  update_translation(data) {
    return this.http.post(this.ApiUrl + "translation/update", data)
  }
  add_phrase(data) {
    return this.http.post(this.ApiUrl + "phrase/create", data)
  }
  update_phrase(phraseId, pageId) {
    return this.http.get(this.ApiUrl + "phrase/update/" + phraseId + "/" + pageId)
  }

  allAdditional() {
    return this.http.get(this.ApiUrl + "additional/all");
  }
  updateAdditional(data) {
    return this.http.post(this.ApiUrl + "additional/create", data);
  }
  editcompanyfromadminpannel(data) {
    return this.http.post(this.ApiUrl + "customer/update", data, {
      headers: { "Authorization": `Bearer ${localStorage.getItem('verifiedflash268')}` }
    });
  }
  editcustomerfromadminpannel(id, data) {
    return this.http.post(this.ApiUrl + "customer/editcustomerfromadminpannel/" + id, data);
  }
  resetPaswordCustomer(id, data) {
    return this.http.post(this.ApiUrl + "customer/resetfromadmin/" + id, data);
  }
  bulkidtoname(data) {
    return this.http.post(this.ApiUrl + "service/bulkidtoname", data);
  }
  Edit_Comserv(id, data) {
    return this.http.post(this.ApiUrl + "comserv/edit/" + id, data);
  }

  all_mobile_pages() {
    return this.http.get(this.ApiUrl + "page/all")
  }
  add_mobile_page(data) {
    return this.http.post(this.ApiUrl + "page/create", data)
  }
  update_mobile_page(id, data) {
    return this.http.post(this.ApiUrl + "page/update/" + id, data)
  }
  delete_mobile_page(id) {
    return this.http.delete('')
  }
}
