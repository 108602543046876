import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
@Component({
  selector: 'app-projectslookingfor',
  templateUrl: './projectslookingfor.component.html',
  styleUrls: ['./projectslookingfor.component.scss']
})
export class ProjectslookingforComponent implements OnInit {


  url = environment.baseUrl;
  term: any;
  perPage = 10
  totalItems = 0
  constructor(
    public r: Router,
    private modalService: NgbModal,
    public d: DataService,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Projects';
  }
  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  perPageChange(e) {
    let val = e.target.value
    if(val === 'All') val = this.mfData.length
    this.perPage = val
  }
  mfData = [];
  page: number = 1;
  ngOnInit() {
    this.mfData = [];
    this.d.getAllProject().subscribe((responce: any) => {
      this.mfData = responce.data;
      this.totalItems = this.mfData.length
      console.log(responce.data);
    });
  }
  sort() {
    this.mfData.sort((a, b) => a.customer.contactName.localeCompare(b.customer.contactName))
  }
  // ========== delete ===========
  deleid: any;
  getdelId(id) {
    this.deleid = id;
  }
  del() {
    console.log(this.deleid)
    this.d.removeLookingfor(this.deleid).subscribe((res: any) => {
      this.ngOnInit()
    })
  }
  getedit(item) {
    localStorage.setItem('editlookingfor', JSON.stringify(item));
    this.r.navigate(['/editlookingfor'])
  }
}
