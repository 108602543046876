import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { FileUploadService } from '../file-upload.service';

@Component({
  selector: 'app-editserviceprovider',
  templateUrl: './editserviceprovider.component.html',
  styleUrls: ["../../vendor/libs/ngx-toastr/ngx-toastr.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EditserviceproviderComponent implements OnInit {
  url = environment.baseUrl;
  curMsgIndex = -1;
  title = "Notification";
  message = "Successfully updated";
  type = "success";
  tapToDismiss = true;
  closeButton = true;
  progressBar = false;
  preventDuplicates = false;
  newestOnTop = false;
  progressAnimation = "increasing";
  positionClass = "toast-top-right";

  constructor(
    private appService: AppService,
    public toastrService: ToastrService,
    private modalService: NgbModal,
    public d: DataService,
    private f: FileUploadService) { }

  data = {
    id: 0,
    contactName: '',
    comHST: '',
    comLegalName: '',
    comContactEmail: '',
    mobileNumber: '',
    email: '',
    comWebsite: '',
    comRegistrationNumber: '',
    comServiceDistanceTo: '',
    country: '',
    city: '',
    province: '',
    postalCode: '',
    streetAddress: '',
    logo: ''
  }
  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('editserviceprovider'));
    console.log(this.data);
    this.d.getAllCategory().subscribe((resp: any) => {
      this.catagories = resp.data;
    })
    this.d.getAllSubCat(null).subscribe((resp: any) => {
      this.subcatagories = resp.data;
    })
    this.d.getAllService().subscribe((resp: any) => {
      this.services = [];
      console.log(resp.data);
      resp.data.forEach(element => {
        this.services.push(element.name)
      });
    })
    this.d.getComserv(this.data.id).subscribe((resp: any) => {
      console.log(resp);
      this.edit_comserv_object.catsubcatId = resp[0].catsubcatId;
      this.edit_comserv_object.mainId = resp[0].mainId;
      let data = {
        arrayOfIds: []
      }
      resp.forEach(element => {
        data.arrayOfIds.push(element.serviceId);
      });
      this.d.bulkidtoname(data).subscribe((resp: any) => {
        console.log(resp);
        resp.forEach(element => {
          this.edit_comserv_object.servicesname.push(element.name)
        });
      })
    })
  }
  getImage(logo) {
    if (logo !== '') {
      return this.url + logo;
    } else {
      return 'assets/avatar.png'
    }
  }
  fileSelected(event) {
    const file: File | null = event.target.files.item(0)
    if(file) {
      this.f.upload(file).subscribe((resp: any) => {
        let fname = resp.body
        this.d.updateProfileImageCompany({logo: fname}, this.data.id).subscribe(res => {
          this.data['logo'] = fname
        })
      })
    }
  }
  catagories = [];
  subcatagories = [];
  services = [];
  allcomservs = [];
  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  edit_comserv_object = {
    catsubcatId: 0,
    mainId: 0,
    servicesname: []
  }
  updateComserv() {
    console.log(this.edit_comserv_object);
    this.d.Edit_Comserv(this.data.id,this.edit_comserv_object).subscribe((resp:any)=>{
      console.log(resp);
    })
  }
  onChange(val) {
    console.log(val);
    this.edit_comserv_object.servicesname = val;
  }
  save() {
    console.log(this.data);
    this.d.editcompanyfromadminpannel(this.data).subscribe((resp: any) => {
      console.log(resp);
    })
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }
  showToast() {
    console.log('toast called')
    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: this.appService.isRTL
    };

    this.toastrService.toastrConfig.newestOnTop = this.newestOnTop;
    this.toastrService.toastrConfig.preventDuplicates = this.preventDuplicates;

    this.toastrService[this.type](this.message, this.title, options);
  }

  clearToasts() {
    this.toastrService.clear();
  }
}
