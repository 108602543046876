import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import * as numeral from 'numeral';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment.prod';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: [
    '../../vendor/styles/pages/products.scss',
    '../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    '../../vendor/libs/ng-select/ng-select.scss',
    './services.component.scss'
  ],
})
export class ServicesComponent {
  term = ''
  isRTL: boolean;
  allCats = [];
  url: any;
  constructor(private http: HttpClient,
    public d: DataService,
    private modalService: NgbModal, private appService: AppService) {
    this.appService.pageTitle = 'Services';
    this.isRTL = appService.isRTL;

    if (appService.isRTL) {
      this.filterSalesOptions['direction'] = 'rtl';
      this.filterPriceOptions['direction'] = 'rtl';
    }
    this.url = environment.baseUrl;
    this.loadData();
  }


  open(content, options = {}) {
    this.modalService.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  // Filters

  filterSales = [10, 834];
  filterPrice = [10, 2000];
  filterStatus = 'Any';

  // Filter options
  filterSalesOptions: Object = {
    step: 10,
    tooltips: true,
    range: {
      'min': [10],
      'max': [834]
    },
    direction: 'ltr',
    format: {
      to: function (value: any) {
        return Math.round(value);
      },
      from: function (value) {
        return value;
      }
    }
  };
  filterPriceOptions: Object = {
    step: 50,
    tooltips: true,
    range: {
      'min': [10],
      'max': [2000]
    },
    direction: 'ltr',
    format: {
      to: function (value) {
        return numeral(value).format('$0,0');
      },
      from: function (value) {
        return value.replace(/[$,]/g, '');
      }
    }
  };

  // Table

  // Options
  // dataUrl = 'assets/json/pages_e-commerce_product-list.json';
  searchKeys = ['name'];
  sortBy = 'name';
  sortDesc = false;
  perPage = 10;

  filterVal = '';
  currentPage = 1;
  totalItems = 0;

  productsData: Object[] = [];
  originalProductsData: Object[] = [];

  formatPrice(price, dropDecimals = false) {
    return numeral(price).format(dropDecimals ? '$0,0' : '$0,0.00');
  }

  formatNumber(value) {
    return numeral(value).format('0,0');
  }

  loadData() {
    this.selectOptions = [];
    this.originalProductsData = [];
    this.allCats = [];
    this.d.getAllSubCat(null).subscribe((res: any) => {
      this.value = res.data;
      res.data.forEach(element => {
        this.selectOptions.push({ value: element.id, label: element.name })
      });
    })
    this.d.getAllService().subscribe((resp: any) => {
      this.allCats = resp.data;
      // this.totalItems = resp.length;
      this.originalProductsData = resp.data.slice(0);
      this.update()
      // this.originalProductsData = resp.slice(0);
      // this.productsData = resp
      // this.update();
      console.log(resp.data)
    }, err => {
      console.log(err)
    });
  }

 
  perPageChange(e) {
    let val = e.target.value
    if (val === 'All') val = this.allCats.length
    this.perPage = val
    this.update()
  }

  update() {
    const data = this.filter(this.originalProductsData);

    this.totalItems = data.length;

    this.sort(data);
  }

  filter(data) {
    const filter = this.filterVal.toLowerCase();
    return !filter ?
      data.slice(0) :
      data.filter(d => {
        return Object.keys(d)
          .filter(k => this.searchKeys.includes(k))
          .map(k => String(d[k]))
          .join('|')
          .toLowerCase()
          .indexOf(filter) !== -1 || !filter;
      });
  }

  sort(data) {
    data.sort((a: any, b: any) => {
      a = typeof (a[this.sortBy]) === 'string' ? a[this.sortBy].toUpperCase() : a[this.sortBy];
      b = typeof (b[this.sortBy]) === 'string' ? b[this.sortBy].toUpperCase() : b[this.sortBy];

      if (a < b) { return this.sortDesc ? 1 : -1; }
      if (a > b) { return this.sortDesc ? -1 : 1; }
      return 0;
    });
  }

 
  setSort(key) {
    if (this.sortBy !== key) {
      this.sortBy = key;
      this.sortDesc = false;
    } else {
      this.sortDesc = !this.sortDesc;
    }

    this.currentPage = 1;
    this.update();
  }

  key: string = 'name';
  reverse: boolean = false;

  sortData(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  // ===============
  disabled = false;
  selectOptions = [ // tslint:disable
  ]; // tslint:enable
  value: any;
  filename = 'Choose file';
  imag: any;
  addData = {
    name: '',
    catsubcatId: ''
  }
  catId: any;

  selectFile(event) {
    this.imag = event.target.files[0];
    this.filename = this.imag.name;
    // this.d.uploadFiles(event.target.files[0]).subscribe((resp: any) => {
    //   console.log(resp);
    //   this.addData._imageUrl = resp;
    //   this.updateData._imageUrl = resp;
    // })
  }
  save() {
    // this.selectOptions.forEach(element => {
    //   if (element.value == this.catId) {
    //     console.log(element.label)
    //     this.addData._categoryName = element.label;
    //   }
    // });
    console.log(this.addData);
    this.d.addService(this.addData).subscribe((resp: any) => {
      console.log(resp);
      this.loadData()
      this.addData.name = '';
      // this.addData._categoryName = '';
      // this.addData._imageUrl = '';
      // this.filename = 'Choose Image'
      // this.allCats = [];
      // this.d.show_catsubcat().subscribe((resp: any) => {
      //   this.allCats = resp
      // });
    })
  }
  // ========== delete ===========
  deleid: any;
  getdelId(id) {
    this.deleid = id;
  }
  del() {
    console.log(this.deleid)
    this.d.removeService(this.deleid).subscribe((res: any) => {
      this.loadData()
    })
  }
  // ===========edit===========
  updateData = {
    id: '',
    name: '',
  }
  editId: any;
  getedit(edit) {
    console.log(edit);
    this.editId = edit.id;
    this.updateData.name = edit.name;
  }
  updateCAT() {
    this.updateData.id = this.editId;
    this.d.editService(this.updateData).subscribe((resp: any) => {
      this.allCats = [];
      console.log(resp);
      this.loadData()
    })
  }
}
